import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ContentfulAssetQuery, Img, WmkImage } from "wmk-image";
import { RichText, RichTextReact } from "wmk-rich-text";
import { GatedContentFields } from "../../../fragments/nodeGatedContentFields";
import { colors } from "../../../vars/colors";
import SubHeading from "../../common/typography/SubHeading";
import HubspotForm from "react-hubspot-form";
import styled from "styled-components";
import { WMKGradient } from "../../../vars/palette";
import { SolutionsCtaWrap } from "../../blocks/library";

const StyledForm = styled.div`
  label {
    color: ${colors.white.toString()};
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 2px;
  }
  input {
    &:not([type="submit"]) {
      width: 100%;
      background-color: ${colors.white.alpha(0.9).toString()};
    }
    &[type="submit"] {
      background-color: ${WMKGradient.grad()};
      border: none;
      padding: 0.5vh 1vw;
      min-width: 75%;
      margin: 1.5rem 0;
      font-family: Rajdhani, sans-serif;
      color: ${colors.white.toString()};
      text-transform: uppercase;
    }
  }
  .actions {
    text-align: center;
  }
`;

const OrderedContainer = styled(Container)`
  .pageRow > div:first-child {
    order: 1;
  }
  .pageRow > div:nth-child(2) {
    order: 2;
  }
  @media only screen and (max-width: 767px) {
    .pageRow > div:first-child {
      order: 2;
      padding-top: 2rem;
    }
    .pageRow > div:nth-child(2) {
      order: 1;
    }
  }
`;

const GatedRow = ({ children }: { children: React.ReactNode }) => {
  return <Row style={{ padding: `2rem 4vw` }}  className="pageRow">{children}</Row>;
};

const FormArea = ({
  image,
  formId,
  download,
}: {
  image: Img;
  formId?: string;
  download?: ContentfulAssetQuery;
}) => {
  return (
    <Col
      sm={12}
      md={5}
      lg={4}
      style={{ backgroundColor: colors.purple.toString(), padding: `4vh 2vw` }}
    >
      {download && "file" in download ? (
        <div style={{ width: `100%`, textAlign: "center" }}>
          <SolutionsCtaWrap>
            <a
              href={download.file.url}
              download
              style={{ minWidth: `75%` }}
              target="_blank"
            >
              DOWNLOAD
            </a>
          </SolutionsCtaWrap>
        </div>
      ) : null}
      
      <div style={{ textAlign: "center" }}>
        <WmkImage image={image} />
      </div>
      <StyledForm style={{ padding: `2vh 0` }}>
        {formId ? <HubspotForm formId={formId} portalId={"526959"} /> : null}
      </StyledForm>
    </Col>
  );
};

const StyledContent = styled(Col)`
  a {
    color: ${colors.red.toString()};
    &:hover {
      color: ${colors.purple.toString()};
    }
  }
`;

const ContentArea = ({
  headline = "Thank you!",
  subhead,
  copy,
}: {
  headline?: string;
  subhead?: string;
  copy?: RichText;
}) => {
  return (
    <StyledContent sm={12} md={7} lg={8}>
      {subhead ? (
        <p
          style={{
            fontFamily: `Rajdhani, sans-serif`,
            color: colors.lightOrange.toString(),
          }}
        >
          {subhead}
        </p>
      ) : null}
      <SubHeading textCase="lowercase" text={headline} />
      <RichTextReact content={copy} />
    </StyledContent>
  );
};

export const GatedLandingPage = ({ page }: { page: GatedContentFields }) => {
  return (
    <OrderedContainer fluid>
      <GatedRow>
        <ContentArea
          headline={page.title}
          subhead={page.subhead}
          copy={new RichText(page.landingPageCopy)}
        />
        <FormArea image={new Img(page.formImage)} formId={page.hubspotFormId} />
      </GatedRow>
    </OrderedContainer>
  );
};

export const GatedThankYouPage = ({ page }: { page: GatedContentFields }) => {
  return (
    <Container fluid>
      <GatedRow>
        <ContentArea
          headline={page.thankYouPageHeadline}
          copy={new RichText(page.thankYouPageCopy)}
        />
        <FormArea image={new Img(page.formImage)} download={page.download} />
      </GatedRow>
    </Container>
  );
};
