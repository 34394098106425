import {
  CaseStudyFields,
  CaseStudyV3Fields,
} from "../fragments/nodeCaseStudyFields";
import { Img } from "wmk-image";
import { CaseStudyV2Fields } from "../fragments/nodeCaseStudyV2Fields";
import { RichText } from "wmk-rich-text";
import { ContentfulImageQuery } from "wmk-image";

export class CaseStudy3 {
  id: string;
  clientName: string;
  pageTitle: string;
  heroImage: Img;
  logo: Img;
  solutions: string[];
  content: RichText;
  bottomContent: RichText;
  centerImages: Img[];
  slug: string;
  launched: string;
  caseType: "long";
  type: "ContentfulCaseStudy";
  to: string;
  summary: string;
  industry?: string[];
  constructor(node: CaseStudyV3Fields) {
    const _node = { ...node };
    const hero = new Img(_node.featuredImage);
    hero.addCrop("ogImage", _node?.featuredImage?.ogImage);
    hero.addCrop("twitterImage", _node?.featuredImage?.twitterImage);
    hero.addCrop("square", _node?.featuredImage?.square);
    const inds = _node.industries;
    this.id = _node.caseStudyId;
    this.pageTitle = _node.pageTitle;
    this.clientName = _node.title;
    this.heroImage = hero;
    this.logo = new Img(_node.logo);
    this.solutions = _node.services;
    this.content = new RichText(_node.content);
    this.bottomContent = new RichText(_node.bottomContent);
    this.centerImages =
      Array.isArray(_node.centerImages) && _node.centerImages?.length
        ? _node.centerImages.map((image) => {
            return new Img(image);
          })
        : [];
    this.slug = _node.slug;
    this.launched = _node.launchDate;
    this.caseType = "long";
    this.type = _node.type;
    this.to = `/work/${_node.slug}`;
    this.summary = _node.tagline;
    this.industry = Array.isArray(inds) ? [..._node.industries] : [];
  }
}

export class NewCaseStudy {
  title?: string;
  slug?: string;
  clientName: string;
  summary?: string;
  industry?: string;
  website?: string;
  launched?: string;
  logo: { full: Img; white: Img };
  content: {
    background: {
      text: RichText;
      image: Img;
      below: Img;
    };
    bottom: {
      image: Img;
      text: RichText;
    };
    results: {
      text: RichText;
      image: Img;
    };
  };
  isValid: boolean;
  heroImage: Img;
  services: string[];
  caseType: "long";
  to: string;
  type: "ContentfulWorkCaseStudyV2";
  constructor(node: CaseStudyV2Fields) {
    const _node = { ...node };
    const title = _node?.title;
    const slug = _node?.slug;
    const logoWhite = new Img(_node.clientLogoWhite);
    logoWhite.addCrop("thumb", _node?.clientLogoWhite?.thumb);
    const hero = new Img(_node.heroImage);
    hero.addCrop("fixed", _node?.heroImage?.fixed);
    hero.addCrop("galleryHero", _node?.heroImage?.galleryHero);
    hero.addCrop("galleryThumb", _node?.heroImage?.galleryThumb);
    hero.addCrop("ogImage", _node?.heroImage?.ogImage);
    hero.addCrop("twitterImage", _node?.heroImage?.twitterImage);
    this.to = `/work/${_node.slug}`;
    this.title = title;
    this.slug = slug;
    this.summary = _node.rolloverSummary;
    this.content = {
      background: {
        text: new RichText(_node.backgroundCopy),
        image: new Img(_node.backgroundRightColumnImage),
        below: new Img(_node.backgroundBelowCopyMedia),
      },
      bottom: {
        image: new Img(_node.bottomImage),
        text: new RichText(_node.additionalCopy),
      },
      results: {
        text: new RichText(_node.resultsCopy),
        image: new Img(_node.resultsBelowCopyMedia),
      },
    };
    this.logo = { full: new Img(_node.clientLogo), white: logoWhite };
    this.isValid = title && slug ? true : false;
    this.clientName = _node.clientName;
    this.industry = _node.industry;
    this.heroImage = hero;
    this.launched = _node.launchDate;
    this.services = _node.services;
    this.website = _node.clientWebsite;
    this.caseType = "long";
    this.type = _node.type;
  }
}

export interface CaseStudyGallery {
  hero: Img;
  images: {
    mediaType: string;
    imageGallery: Img[];
  }[];
}

export class CaseStudy {
  title: string;
  slug: string;
  clientName: string;
  heroImage?: Img;
  launched?: string;
  summary?: string;
  industry?: string;
  website?: string;
  logo: { full: Img; white: Img };
  content: {
    challenge: {
      text: string;
    };
    results: {
      text: string;
      image: Img;
    };
  };
  gallery: CaseStudyGallery;
  services: string[];
  caseType: "long" | "short";
  to: string;
  type: "ContentfulWorkCaseStudyOld";
  constructor(node: CaseStudyFields) {
    const _node = { ...node };
    const media = _node.media;
    const hero = new Img(_node.heroImage);
    hero.addCrop("ogImage", _node?.heroImage?.ogImage);
    hero.addCrop("twitterImage", _node?.heroImage?.twitterImage);
    hero.addCrop("square", _node?.heroImage?.square);
    this.to = `/work/${_node.slug}`;
    this.title = _node.title;
    this.heroImage = hero;
    this.launched = _node.launchDate;
    this.summary = _node.rolloverSummary;
    this.clientName = _node.clientName;
    this.industry = _node.industry;
    this.website = _node.clientWebsite;
    this.logo = {
      full: new Img(_node.clientLogo),
      white: new Img(_node.clientLogoWhite),
    };
    this.content = {
      challenge: { text: _node?.challengeText?.challengeText },
      results: {
        text: _node?.resultsText?.resultsText,
        image: new Img(_node.resultsHero),
      },
    };
    this.gallery = {
      hero: new Img(_node.galleryHero),
      images: Array.isArray(media)
        ? media.map(
            (m: {
              mediaType: string;
              imageGallery: ContentfulImageQuery[];
            }) => {
              return {
                mediaType: m.mediaType,
                imageGallery: Array.isArray(m.imageGallery)
                  ? m.imageGallery.map((im) => new Img(im))
                  : [],
              };
            }
          )
        : [],
    };
    this.slug = _node.slug;
    this.caseType = "long";
    this.services = _node.services;
    this.type = _node.type;
  }
}

export class CaseStudies {
  list: (CaseStudy | CaseStudy3)[];
  length: number;
  groups: {
    long: (CaseStudy | CaseStudy3)[];
    short: CaseStudy[];
  };
  constructor(query: { node: CaseStudyFields | CaseStudyV3Fields }[]) {
    const edges = [...query];
    const list = edges.map((e) => {
      const type = e.node.type;
      return type === "ContentfulWorkCaseStudyOld"
        ? new CaseStudy(e.node)
        : new CaseStudy3(e.node);
    });
    list.sort((a, b) => {
      return a.launched < b.launched ? 1 : a.launched > b.launched ? -1 : 0;
    });
    this.list = list;
    this.length = list.length;
    this.groups = list.reduce(
      (hash, cStudy) => {
        const arr = hash[cStudy?.caseType];
        if (Array.isArray(arr)) {
          hash[cStudy.caseType].push(cStudy);
        }
        return hash;
      },
      { long: [], short: [] }
    );
  }
}
