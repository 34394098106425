import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Banner from "./WeHelpBanner";
import Tile from "../../../Work/GelbertGallery/parts/GelbertTile";
import {
  CaseStudies,
  CaseStudy,
  CaseStudy3,
} from "../../../../../classes/CaseStudy";
import { useStaticQuery, graphql } from "gatsby";
//import { CaseStudyQuery } from "../../../../../node/caseStudy";
import {
  CaseStudyFields,
  CaseStudyV3Fields,
} from "../../../../../fragments/nodeCaseStudyFields";

const Wrap = styled.div`
  position: relative;
  left: 0%;
  margin-top: 3.5rem;
  .container,
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
    .row {
      margin: 10px 0 30px !important;
      .col {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`;

const RelatedCaseStudies = ({
  moreWork,
  currCaseStudy,
}: {
  moreWork: (CaseStudy | CaseStudy3)[];
  currCaseStudy: string;
}) => {
  const data: {
    allWork: { edges: { node: CaseStudyFields }[] };
    allWorkV3: { edges: { node: CaseStudyV3Fields }[] };
  } = useStaticQuery(query);
  const cases = new CaseStudies([
    ...data.allWork.edges,
    ...data.allWorkV3.edges,
  ]);
  cases.list.sort((a, b) => {
    const aDate = a.launched || "0";
    const bDate = b.launched || "0";
    return aDate.localeCompare(bDate);
  });
  const filtered = cases.list.filter((c) => {
    const isSchema = c.slug.match(/schema/i);

    return (
      !Array.isArray(isSchema) &&
      moreWork.reduce((matches, work) => {
        if (work.clientName === c.clientName) {
          matches.push(work);
        }
        return matches;
      }, []).length === 0
    );
  });

  const more =
    moreWork.length < 4
      ? [...moreWork, ...filtered.slice(moreWork.length - 1, 4)]
      : moreWork.slice(0, 4);
  return (
    <Wrap>
      <Banner />
      <Container fluid>
        <Row>
          {more.map((piece, i) => {
            return piece.clientName !== currCaseStudy ? (
              <Col
                style={{ padding: 0 }}
                key={piece.clientName + i}
                lg={3}
                md={6}
                sm={6}
                xs={12}
              >
                <Tile work={piece} />
              </Col>
            ) : null;
          })}
        </Row>
      </Container>
    </Wrap>
  );
};

export default RelatedCaseStudies;

const query = graphql`
  {
    allWorkV3: allContentfulCaseStudy(
      sort: { fields: launchDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          ...NodeCaseStudyV3ExcerptFields
        }
      }
    }
    allWork: allContentfulWorkCaseStudyOld(
      sort: { fields: launchDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          ...NodeCaseStudyExcerptFields
        }
      }
    }
  }
`;
