import * as React from "react";
import styled from "styled-components";
import Theme from "../../../../../vars/ThemeOptions";
import { WmkLink } from "wmk-link";
import { WmkImage } from "wmk-image";
import { CaseStudy, CaseStudy3 } from "../../../../../classes/CaseStudy";

const StyledTile = styled(WmkLink)`
  display: block;
  overflow: hidden;
  position: relative;
  a {
    text-decoration: none;
  }
  .gatsby-image-wrapper {
    transform: scale(1);
    transition: transform 0.3s ease;
  }
  .logo {
    opacity: 0;
    transition: all 0.6s ease;
  }
  &:hover {
    .gatsby-image-wrapper {
      transform: scale(1.25);
      transition: transform 0.3s ease;
    }
    .logo {
      opacity: 1;
      transition: all 0.6s ease;
    }
    .hover-bg {
      .fire-bg {
        opacity: 0.75;
        transition: all 0.3s ease;
      }
    }
    .content {
      text-decoration: none;
      color: ${Theme.hex("white")};
    }
  }
`;

const StyledHoverBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${Theme.gradient.black}
  transition: all .3s ease;
  .fire-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${Theme.hex("fire")};
    opacity: 0;
    transition: all 0.3s ease;
  }
`;

const StyledContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${Theme.hex("white")};
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 1rem;
`;

const StyledLogo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  transition: all 0.4s ease;
  & > div {
    max-width: 65%;
    margin: auto;
  }
`;

const GelbertTile = ({ work }: { work: CaseStudy | CaseStudy3 }) => {
  const { heroImage, clientName } = work;
  return (
    <StyledTile to={work.to}>
      <WmkImage image={heroImage} crop="square" />
      <StyledHoverBg className="hover-bg">
        <div className="fire-bg" />
      </StyledHoverBg>
      <StyledLogo>
        <WmkImage
          className="logo"
          image={
            work.type === "ContentfulWorkCaseStudyOld"
              ? work?.logo?.white
              : work.logo
          }
        />
      </StyledLogo>
      <StyledContent className="content">{clientName}</StyledContent>
    </StyledTile>
  );
};

export default GelbertTile;
