import React from "react";
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from "styled-components";
import Theme from "../../../../../vars/ThemeOptions";

const Wrap = styled.div`
  background: ${Theme.gradient.wmk};
  padding: 1rem 0;
  color: white;
  text-align: center;
  position: relative;
  z-index: 5;
  h4,
  p {
    text-transform: uppercase;
  }
  h4 {
    font-size: 3rem;
    font-weight: 200;
  }
  p {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0;
  }
`;

const WeHelpBanner = () => {
  return (
    <Wrap>
      <h4>We Help Companies Grow</h4>
      <p>Let's Work Together</p>
    </Wrap>
  );
};

export default WeHelpBanner;
