import { graphql } from "gatsby";
import * as React from "react";
import { WmkSeo } from "wmk-seo";
import { CaseStudies } from "../classes/CaseStudy";
import Layout from "../components/layout/MainLayout";
import RelatedCaseStudies from "../components/routes/CaseStudy/GelbertCaseStudy/WeHelpSec/WeHelpSec";
import { GatedLandingPage } from "../components/routes/Gated/GatedContent";
import { GatedContentFields } from "../fragments/nodeGatedContentFields";

const NodeGatedLandingPage = ({ data }: { data: GatedContentQuery }) => {
  const cases = new CaseStudies(
    data.page.caseStudies.map((c) => {
      return { node: c };
    })
  );
  return (
    <Layout>
      <WmkSeo.Meta
        title={data.page.title}
        baseUrl={data.meta.baseUrl}
        slug={data.page.slug}
        path="/lp/"
        siteTitle={data.meta.title}
        ogImageUrl={data.page.formImage.file.url}
        twitterImageUrl={data.page.formImage.file.url}
        description={data?.page?.metaDescription?.metaDescription}
      />
      <GatedLandingPage page={data.page} />
      <RelatedCaseStudies moreWork={cases.list} currCaseStudy="" />
    </Layout>
  );
};

export default NodeGatedLandingPage;

export interface GatedContentQuery {
  page: GatedContentFields;
  meta: { title: string; baseUrl: string };
}

export const query = graphql`
  query glpQuery($slug: String!) {
    page: contentfulGatedContent(slug: { eq: $slug }) {
      ...NodeGatedContentFields
    }
    meta: contentfulGlobalOptions(slug: { eq: "main-options" }) {
      title: siteTitle
      baseUrl: siteUrl
    }
  }
`;
